import React from "react";

const Header = () => {
    return (
        <>
        <h1>HANGMAN</h1>
        <p>ENTER A LETTER ON THE <b>KEYBOARD</b></p>

        </>
    )

}

export default Header 